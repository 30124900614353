export const resultEn = {
	result_screen: {
		well_done: "Well done!",
		prevention_step: "This quickly, you've taken a step towards prevention.",
		part_of_group:
			"Now you are part of the group that chooses to live a healthier life",
		continue_recommendations:
			"Don't stop and keep following the recommendations",
		your_result_is: "Your result is:",
		what_does_it_mean: "What does this mean in my case?",
		want_to_know_more:
			"Do you want to know this and other valuable information?",
		enter_code:
			"Enter the code that we sent to your email to get your full report.",
		segments: {
			under_40: {
				green: {
					label: "Green",
					message: "Average risk for your age",
				},
				yellow: {
					label: "Yellow",
					message: "Risk above average for your age",
				},
				red: {
					label: "Red",
					message: "Risk very above average for your age",
				},
			},
			over_40: {
				yellow: {
					label: "Yellow",
					message: "Average risk for your age",
				},
				orange: {
					label: "Orange",
					message: "Risk above average for your age",
				},
				red: {
					label: "Red",
					message: "Risk very above average for your age",
				},
			},
		},
		prevention_plan_title: "PERSONALIZED PREVENTION PLAN",
		important_consideration:
			"According to your risk profile, it is very important that you consider this plan:",
		good_news: "Good news!",
		no_tests_needed:
			"Given your risk profile, you still do not need to undergo preventive tests (you do not require laboratory or imaging studies).",
		cancer_symptoms_warning:
			"However, remember that symptoms such as: feeling a new lump in your body, unintentional weight loss, fever not related to an infection, can be some of the non-specific symptoms of cancer.",
		advisory_offer:
			"If you develop any of these symptoms, we can advise you via",
		yes: "Yes",
		no: "No",
		answer_questions_continue: "Answer the questions to continue",
		risk_factors_based_on_program:
			"At the end you will find the risk factors on which your program is based.",
		reduce_your_risk: "REDUCE YOUR RISK",
		actions_to_reduce_risk:
			"Given your profile, there are actions you can take to reduce the likelihood of developing cancer:",
		healthy_lifestyle:
			"Based on your responses, we observe that you lead a healthy lifestyle. Keep it up to limit the risk of developing cancer!",
		non_modifiable_risk_factors:
			"The non-modifiable risk factors that impact your profile and on which your screening program is based:",
		help_prevent_cancer:
			"Would you like to help prevent cancer in your family and circle of friends?",
		copy_url: "Copy URL",
		life_or_death_difference:
			"Remember: This is not a definitive diagnosis, but rather an assessment of your potential cancer risk. The key to survival in cancer cases often lies in receiving timely and appropriate care.",
		print_report: "Print your report",
		know_your_benefit: "Know your benefit",
		contact_us_at: "Contact us at:",
		update_email_title: "Update your email",
		email_label: "Email",
		update_email_message:
			"Please make sure to write it correctly, when you update we will immediately send you an email with your token.",
		cancel_button: "Cancel",
		update_button: "Update",
		updating_button: "Updating...",
		share_title: "Share",
		url_copied_message:
			"The URL has been copied to your clipboard! You just have to paste the content to your preferred medium and share.",
	},
	emailCheck: {
		validating_label: "Validating...",
		copy_and_paste_prompt: "You can copy and paste",
		incorrect_code_alert: "Incorrect Code! :(",
		ensure_correct_code_before_paste:
			"Make sure to copy the 6-digit code correctly before pasting",
		did_not_receive_email: "Didn't receive your email?",
		click_to_resend: "Click to send again",
		can_resend_in_seconds: "You can resend in {seconds} seconds.",
		email_sent_to_check_spam:
			"The email was sent to {email} from 'Duppla Doctor <info@duppla.doctor>', check your SPAM folder as sometimes your email provider may send it there.",
		is_email_incorrect: "Is the email {email} incorrect?",
		click_here_to_update: "Click here to update it",
	},
	accountValidation: {
		thank_you_message: "Thank you for validating your account!",
	},
};

export const resultEs = {
	result_screen: {
		well_done: "¡Bien hecho!",
		prevention_step: "Así de rápido has dado un paso hacia la prevención.",
		part_of_group:
			"Ahora formas parte del grupo que elige vivir de manera más saludable",
		continue_recommendations: "No te detengas y sigue las recomendaciones",
		your_result_is: "Tu resultado es:",
		what_does_it_mean: "¿Qué significa esto en mi caso?",
		want_to_know_more: "¿Quieres conocer ésta y otra información valiosa?",
		enter_code:
			"Inserta el código que te hicimos llegar a tu correo electrónico para obtener tu reporte completo.",
		segments: {
			under_40: {
				green: {
					label: "Verde",
					message: "Riesgo promedio en tu edad",
				},
				yellow: {
					label: "Amarillo",
					message: "Riesgo encima al promedio en tu edad",
				},
				red: {
					label: "Rojo",
					message: "Riesgo muy superior al promedio en tu edad",
				},
			},
			over_40: {
				yellow: {
					label: "Amarillo",
					message: "Riesgo promedio en tu edad",
				},
				orange: {
					label: "Naranja",
					message: "Riesgo encima al promedio en tu edad",
				},
				red: {
					label: "Rojo",
					message: "Riesgo muy superior al promedio en tu edad",
				},
			},
		},
		prevention_plan_title: "PLAN DE PREVENCIÓN PERSONALIZADO",
		important_consideration:
			"Según tu perfil de riesgo, es muy importante que consideres este plan:",
		good_news: "¡Buenas noticias!",
		no_tests_needed:
			"Dado tu perfil de riesgo, aún no es necesario realizarte estudios de prevención (no requieres laboratorios o estudios de imagen).",
		cancer_symptoms_warning:
			"Aun así, recuerda que síntomas como: palpar una bolita nueva en tu cuerpo, pérdida de peso no intencionada, fiebre no relacionada a infección, pueden ser algunos de los síntomas inespecíficos de cáncer.",
		advisory_offer:
			"Si desarrollas alguno de estos síntomas, te podemos asesorar vía",
		yes: "Sí",
		no: "No",
		answer_questions_continue: "Contesta las preguntas para continuar.",
		risk_factors_based_on_program:
			"Al final encontrarás los factores de riesgo en los que se basa tu programa.",
		reduce_your_risk: "REDUCE TU RIESGO",
		actions_to_reduce_risk:
			"Dado tu perfil, hay acciones que puedes emprender para reducir la probabilidad de tener cáncer:",
		healthy_lifestyle:
			"Con base en tus respuestas, observamos que llevas un estilo de vida saludable. ¡Sigue así para limitar el riesgo de desarrollar cáncer!",
		non_modifiable_risk_factors:
			"Los factores de riesgo no modificables que impactan tu perfil y en el cual está basado tu programa de tamizaje:",
		help_prevent_cancer:
			"¿Te gustaría ayudar a prevenir el cáncer en tu familia y círculo de amigos?",
		copy_url: "Copiar URL",
		life_or_death_difference:
			"Recuerda: Este no es un diagnóstico definitivo, es una hipótesis sobre la probabilidad de tener riesgo de padecer cáncer. La diferencia entre la vida y la muerte en una situación de cáncer es que se pueda recibir una atención oportuna y adecuada.",
		print_report: "Imprime tu reporte",
		know_your_benefit: "Conoce tu beneficio",
		contact_us_at: "Contáctanos a:",
		update_email_title: "Actualiza tu correo",
		email_label: "Correo electrónico",
		update_email_message:
			"Por favor asegúrate de escribirlo correctamente, al actualizar te enviaremos inmediatamente un correo con tu token.",
		cancel_button: "Cancelar",
		update_button: "Actualizar",
		updating_button: "Actualizando...",
		share_title: "Compartir",
		url_copied_message:
			"¡La url se ha copiado a tu portapapeles! Sólo tienes que pegar el contenido en tu medio preferido y compartir.",
	},
	emailCheck: {
		validating_label: "Validando...",
		copy_and_paste_prompt: "Puedes copiar y pegar",
		incorrect_code_alert: "Código Incorrecto! :(",
		ensure_correct_code_before_paste:
			"Asegúrate de copiar el código de 6 dígitos de forma correcta antes de pegar",
		did_not_receive_email: "¿No recibiste tu correo?",
		click_to_resend: "Da click para enviar de nuevo",
		can_resend_in_seconds: "Puedes volver a reenviar en {seconds} segundos.",
		email_sent_to_check_spam:
			"El correo fue enviado a {email} desde 'Duppla Doctor <info@duppla.doctor>', revisa tu carpeta de SPAM ya que en ocasiones tu proveedor de correo puede enviarlo ahí.",
		is_email_incorrect: "¿El correo {email}, es incorrecto?",
		click_here_to_update: "Da click aquí para actualizarlo",
	},
	accountValidation: {
		thank_you_message: "¡Gracias por validar tu cuenta!",
	},
};
